<template>
  <div>
    <v-dialog v-model="dialog" persistent scrollable width="800">
      <v-card>
        <v-card-title>
          Plus détails
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider/>

        <v-card-text class="pa-10">
          //
        </v-card-text>
        
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
    data() {
        return {
            dialog: false,
        }
    },
    methods: {
        open() {
            this.dialog = true
        },
    }
}
</script>

<style scoped>

</style>