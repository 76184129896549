<template>
  <div>

    <Lightbox ref="lightbox"/>

    <v-dialog v-model="dialog" persistent scrollable width="900">
      <v-card>
        <v-card-title>
          Plus détails
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider/>

        <v-card-text class="pa-10">

          <v-simple-table class="rounded-lg table-border mb-5">
            <template v-slot:default>
              <tbody>

              <tr>
                <td colspan="2">
                  <div class="fs-16 d-flex align-center">
                    <v-icon class="mr-1" color="primary">mdi-clock</v-icon>
                    Info & Plannification
                    <v-icon v-if="!campaign.with_objectives" class="ml-2" color="red">mdi-close-circle</v-icon>
                  </div>
                </td>
              </tr>

              <tr class="grey lighten-5">
                <td class="font-weight-medium">Statut</td>
                <td>
                  <v-chip v-if="campaign.is_active"
                          class="font-weight-medium bg-success-subtle"
                          small
                          text-color="success">Actif
                  </v-chip>

                  <v-chip v-else
                          class="font-weight-medium bg-red-subtle"
                          small
                          text-color="red">Inactif
                  </v-chip>
                </td>
              </tr>

              <tr>
                <td class="font-weight-medium">Image</td>
                <td>
                  <v-avatar rounded size="42">
                    <v-img v-if="campaign.image" :src="$baseUrl + campaign.image"
                           class="zoom-pointer rounded-lg"
                           max-width="42"
                           min-height="42"
                           @click="$refs.lightbox.open(campaign.image)"/>

                    <v-img v-else
                           :src="require('@/assets/no-image.png')"
                           class="rounded-lg"
                           max-width="42"
                           min-height="42"></v-img>
                  </v-avatar>
                </td>
              </tr>

              <tr class="grey lighten-5">
                <td class="font-weight-medium">Nom de la campagne</td>
                <td>
                  {{ campaign.name }}
                </td>
              </tr>

              <tr>
                <td class="font-weight-medium">Type</td>
                <td>
                  <v-icon color="secondary" dense>mdi-tag-outline</v-icon>
                  {{ campaign.type }}
                </td>
              </tr>

              <tr>
                <td class="font-weight-medium">Date de début</td>
                <td>
                  <v-icon color="primary" dense>mdi-clock-outline</v-icon>
                  {{ $func.dateFormat(campaign.start_date) }}
                </td>
              </tr>


              <tr class="grey lighten-5">
                <td class="font-weight-medium">Date de fin</td>
                <td>
                  <v-icon color="primary" dense>mdi-clock-outline</v-icon>
                  {{ $func.dateFormat(campaign.end_date) }}
                </td>
              </tr>


              <tr>
                <td class="font-weight-medium text-no-wrap">Les informations client sont requises</td>
                <td>
                  <v-icon v-if="campaign.customer_info === 'required'" color="success" dense>mdi-check-circle</v-icon>
                  <v-icon v-else color="red" dense>mdi-close-circle</v-icon>
                </td>
              </tr>


              <tr class="grey lighten-5">
                <td class="font-weight-medium">L'animateur doit relever les contacts indirect</td>
                <td>
                  <v-icon v-if="campaign.note_contact" color="success" dense>mdi-check-circle</v-icon>
                  <v-icon v-else color="red" dense>mdi-close-circle</v-icon>
                </td>
              </tr>

              <tr>
                <td class="font-weight-medium">Date de création</td>
                <td>
                  <v-icon color="primary" dense>mdi-clock</v-icon>
                  {{ $func.dateTimeFormat(campaign.created_at) }}
                </td>
              </tr>

              </tbody>
            </template>
          </v-simple-table>

          <v-simple-table class="rounded-lg table-border mb-5">
            <template v-slot:default>
              <tbody>

              <tr>
                <td colspan="2">
                  <div class="fs-16 d-flex align-center">
                    <v-icon class="mr-1" color="primary">mdi-chart-areaspline</v-icon>
                    Objectifs
                    <v-icon v-if="!campaign.with_objectives" class="ml-2" color="red">mdi-close-circle</v-icon>
                  </div>
                </td>
              </tr>

              <tr v-if="campaign.with_objectives" class="grey lighten-5">
                <td class="font-weight-medium">Objectif global par jour</td>
                <td>
                  {{ campaign.day_global_objective }}
                </td>
              </tr>

              <tr v-if="campaign.with_objectives">
                <td class="font-weight-medium">Objectif global par animateur</td>
                <td>
                  {{ campaign.animator_global_objective }}
                </td>
              </tr>

              <tr v-if="campaign.with_objectives" class="grey lighten-5">
                <td class="font-weight-medium">Objectif refus par jour</td>
                <td>
                  {{ campaign.day_refusal_objective }}
                </td>
              </tr>

              <tr v-if="campaign.with_objectives">
                <td class="font-weight-medium">Objectif refus par animateur</td>
                <td>
                  {{ campaign.animator_refusal_objective }}
                </td>
              </tr>

              <tr v-if="campaign.with_objectives" class="grey lighten-5">
                <td class="font-weight-medium">Objectif switch par jour</td>
                <td>
                  {{ campaign.day_switch_objective }}
                </td>
              </tr>

              <tr v-if="campaign.with_objectives">
                <td class="font-weight-medium">Objectif switch par animateur</td>
                <td>
                  {{ campaign.animator_switch_objective }}
                </td>
              </tr>

              </tbody>
            </template>
          </v-simple-table>

          <v-simple-table class="rounded-lg table-border mb-5">
            <template v-slot:default>
              <tbody>

              <tr>
                <td colspan="2">
                  <div class="fs-16 d-flex align-center">
                    <v-icon class="mr-1" color="primary">mdi-file-tree-outline</v-icon>
                    Marques & produits
                  </div>
                </td>
              </tr>

              <tr v-if="campaign.with_objectives" class="grey lighten-5">
                <td class="font-weight-medium">Marques concernés</td>
                <td>
                  <v-chip v-for="(brand,i) in campaign.brands"
                          :key="i"
                          class="ma-1"
                          color="blue"
                          dark
                          small>
                    {{ brand.name }}
                  </v-chip>
                </td>
              </tr>

              <tr v-if="campaign.with_objectives">
                <td class="font-weight-medium">Produits concernés</td>
                <td>
                  <v-chip v-for="(product,i) in campaign.products"
                          :key="i"
                          class="ma-1"
                          color="primary"
                          dark
                          small>
                    {{ product.name }}
                  </v-chip>
                </td>
              </tr>

              <tr v-if="campaign.with_objectives" class="grey lighten-5">
                <td class="font-weight-medium">Quantité minimale de produit pour gagner un cadeau</td>
                <td>
                  {{ campaign.min_qty_product_win }}
                </td>
              </tr>

              </tbody>
            </template>
          </v-simple-table>

          <v-simple-table class="rounded-lg table-border">
            <template v-slot:default>
              <tbody>

              <tr>
                <td colspan="2">
                  <div class="fs-16 d-flex align-center">
                    <v-icon class="mr-1" color="primary">mdi-gift</v-icon>
                    Cadeaux
                  </div>
                </td>
              </tr>

              <tr class="grey lighten-5">
                <td class="font-weight-medium">Campagne avec des jeux</td>
                <td>
                  <v-icon v-if="campaign.with_game" color="success" dense>mdi-check-circle</v-icon>
                  <v-icon v-else color="red" dense>mdi-close-circle</v-icon>
                </td>
              </tr>

              <tr class="grey lighten-5">
                <td class="font-weight-medium">Jeux de la campagne</td>
                <td>
                  <v-icon v-if="!campaign.with_game" color="red" dense>mdi-close-circle</v-icon>
                  <div v-else class="py-2">
                    <img :src="require('@/assets/'+campaign.game+'.jpg')"
                         alt=""
                         class="rounded-lg"
                         height="70" width="70">
                  </div>
                </td>
              </tr>

              <tr>
                <td class="font-weight-medium">Cadeaux</td>
                <td>
                  <v-list >
                    <v-list-item v-for="(gift,i)  in campaign.gifts" :key="i"
                                 :three-line="!!(gift.is_digital)">
                      <v-list-item-avatar tile>
                        <v-img :src="$baseUrl + gift.image"
                               class="zoom-pointer rounded"
                               contain
                               max-height="60"
                               max-width="60"
                               @click="$refs.lightbox.open(gift.image)"
                        ></v-img>
                      </v-list-item-avatar>

                      <v-list-item-title>
                        {{ gift.name }}

                        <v-list-item-subtitle v-if="campaign.with_game">
                             Pourcentage :
                          <span class="font-weight-medium primary--text">{{ gift.pivot.percentage }}%</span>
                        </v-list-item-subtitle>

                        <v-list-item-subtitle v-if="gift.is_digital">
                             Digital :
                           <span class="font-weight-medium primary--text">
                              ( {{ gift.pivot.digital_amount }} Pts. {{ gift.type === 'gifty_point' ? 'Gifty' : 'Flexy' }} )
                           </span>
                        </v-list-item-subtitle>

                        <v-list-item-subtitle v-if="gift.is_digital">
                              Destinataire  :
                              <span class="font-weight-medium primary--text" v-if="gift.pivot.recipient_digital_amount === 'consumer'">le consommateur</span>
                              <span class="font-weight-medium primary--text" v-if="gift.pivot.recipient_digital_amount === 'salepoint'">le pos</span>
                              <span class="font-weight-medium primary--text" v-if="gift.pivot.recipient_digital_amount === 'animator'">l'animateur</span>
                        </v-list-item-subtitle>
                      </v-list-item-title>


                    </v-list-item>
                  </v-list>
                </td>
              </tr>

              </tbody>
            </template>
          </v-simple-table>

        </v-card-text>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
   data() {
      return {
         dialog: false,
         campaign: {},
      }
   },
   methods: {
      open(campaign) {
         this.campaign = campaign
         this.dialog = true
      },
   }
}
</script>

<style scoped>

</style>